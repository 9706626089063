import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../components/Breadcrumb'
import HeadingUnderline from '../../components/HeadingUnderline'
import { Document, Page, pdfjs } from 'react-pdf'
import jsPDF from 'jspdf'
import '../../lib/PDF'
import { useSearchParams } from 'react-router-dom'

function Trade() {
	const { t } = useTranslation()
  const [searchParams] = useSearchParams();
  const selectedPage = searchParams.get('page')
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
  const [pdfInstance, setPdfInstance] = useState(null)
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const [activeSection, setActiveSection] = useState(selectedPage || 'agriculture')
  const viewerRef = useRef(null)
  const inputFile = 'assets/docs/Central Javas MSMES Premium Products Catalogue Book 2024_Final.pdf'

  function onDocumentLoadSuccess({ numPages }) {
    let page = 1;
    switch (activeSection) {
      case 'agriculture':
        page = 3
        break
      case 'fashion':
        page = 45
        break
      case 'food':
        page = 147
        break
      case 'furniture':
        page = 232
        break
      default:
        page = 3
        break
    }
    setNumPages(numPages)
    setPageNumber(page)
  }
  const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('trade') }]

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = pdfjs.getDocument(inputFile)
      const pdf = await loadingTask.promise
      setPdfInstance(pdf)
    }

    loadPdf()
  }, [])

  const renderPageToCanvas = async (pdf, pageNumber) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1.5 })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.height = viewport.height
    canvas.width = viewport.width

    const renderContext = {
      canvasContext: context,
      viewport
    }

    await page.render(renderContext).promise
    return canvas
  }

  const downloadPages = async (startPage, endPage) => {
    if (!pdfInstance || startPage < 1 || endPage > numPages) {
      alert('Halaman tidak valid.')
      return
    }

    setIsDownloadLoading(true)

    const doc = new jsPDF()

    for (let i = startPage; i <= endPage; i++) {
      const canvas = await renderPageToCanvas(pdfInstance, i)
      const imgData = canvas.toDataURL('image/jpeg', 1)

      if (i > startPage) doc.addPage()
      const imgWidth = 210
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      doc.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST')
    }

    doc.save(`kerisjateng_${activeSection}_catalogue.pdf`)

    setIsDownloadLoading(false)
  }

  const renderButtons = () => {
    return (
      <>
        <button
          className={`${activeSection === 'agriculture' ? 'bg-primary' : 'bg-gold'} rounded-md text-white p-3 hover:opacity-80 disabled:hover:opacity-100 w-full`}
          disabled={activeSection === 'agriculture'}
          onClick={() => {
            setPageNumber(3)
            setActiveSection('agriculture')
          }}
        >
          Agriculture
        </button>
        <button
          className={`${activeSection === 'fashion' ? 'bg-primary' : 'bg-gold'} rounded-md text-white p-3 hover:opacity-80 disabled:hover:opacity-100 w-full`}
          disabled={activeSection === 'fashion'}
          onClick={() => {
            setPageNumber(45)
            setActiveSection('fashion')
          }}
        >
          Fashion & Accessories
        </button>
        <button
          className={`${activeSection === 'food' ? 'bg-primary' : 'bg-gold'} rounded-md text-white p-3 hover:opacity-80 disabled:hover:opacity-100 w-full`}
          disabled={activeSection === 'food'}
          onClick={() => {
            setPageNumber(147)
            setActiveSection('food')
          }}
        >
          Food & Beverages
        </button>
        <button
          className={`${activeSection === 'furniture' ? 'bg-primary' : 'bg-gold'} rounded-md text-white p-3 hover:opacity-80 disabled:hover:opacity-100 w-full`}
          disabled={activeSection === 'furniture'}
          onClick={() => {
            setPageNumber(232)
            setActiveSection('furniture')
          }}
        >
          Furniture & Home Decoration
        </button>
        <button
          className={`${activeSection === 'furniture' ? 'bg-primary' : 'bg-gold'} rounded-md text-white p-3 hover:opacity-80 disabled:hover:opacity-100 w-full`}
          disabled={activeSection === 'furniture'}
          onClick={() => {
            window.open('https://drive.google.com/file/d/1uu1L_0sx7TR2l70S3t4H54PFF84TbmPt/view?usp=sharing', '_blank')
          }}
        >
          UMKM Dekranas
        </button>
      </>
    )
  }

  return (
    <div className='flex flex-col justify-center mb-12'>
      <div className='w-full'>
        <img src={`${process.env.REACT_APP_IMAGE_URL}/Perdagangan.png`} alt='Banner' className='w-full h-[335px] object-cover object-[0_-620px]' />
      </div>
      <div className='max-w-6xl w-full mt-5 md:mt-5 p-2 md:p-0 md:mx-auto'>
        <Breadcrumb items={breadcrumbItems} />
        <h1 className='text-3xl font-bold mb-2 mt-8'>{t('trade')}</h1>
        <HeadingUnderline />
        <div className='grid grid-cols-1 md:grid-cols-5 gap-2 my-5'>{renderButtons()}</div>

        <div className={`flex flex-col justify-center items-center`} ref={viewerRef}>
          <Document file={inputFile} onLoadSuccess={onDocumentLoadSuccess}>
            <div className={`flex gap-2 flex-col`}>
              <div style={{ width: Math.min(600, width * 0.9), aspectRatio: '2/3' }}>
                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} className={'w-full'} width={Math.min(600, width * 0.9)} />
              </div>
              <div className={`flex flex-col justify-between items-center w-full gap-2`}>
                <div className='flex justify-between items-center w-full gap-2'>
                  <button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)} className={`w-full bg-gold rounded-md text-white p-3 hover:opacity-80`}>
                    <i className='fa fa-caret-left'></i>
                  </button>
                  <button disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)} className={`w-full bg-gold rounded-md text-white p-3 hover:opacity-80`}>
                    <i className='fa fa-caret-right'></i>
                  </button>
                </div>
                <button
                  onClick={() => {
                    switch (activeSection) {
                      case 'agriculture':
                        downloadPages(3, 44)
                        break
                      case 'fashion':
                        downloadPages(45, 145)
                        break
                      case 'food':
                        downloadPages(147, 231)
                        break
                      case 'furniture':
                        downloadPages(232, 302)
                        break
                      default:
                        downloadPages(3, 44)
                        break
                    }
                  }}
                  disabled={isDownloadLoading}
                  className='bg-gold rounded-md text-white p-3 hover:opacity-80 disabled:bg-gray-300 w-full'
                >
                  {isDownloadLoading ? t('loading') : t('download')}
                </button>
              </div>
            </div>
          </Document>
        </div>
      </div>
    </div>
  )
}

export default Trade
