import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const TabButton = ({ logo, label, isActive, onClick }) => {
	return (
		<button
			className={`w-full text-center md:text-left my-1 px-6 box-border py-3 uppercase rounded-md md:rounded-none border border-gray-300 md:border-0 ${
				isActive && 'font-bold border-b-4 md:border-l-4 border-b-gold md:border-b-0 md:border-l-gold bg-[#e0aa3e33]'
			}`}
			onClick={onClick}
		>
			{label}
      { logo && <img src={logo} alt={label} className='w-6 h-6 inline-block ml-2' /> }
		</button>
	)
}

const TabContent = ({ activeTab, tabData, contentWidth }) => {
	const style = {
		'@media (maxWidth: 768px)': {
			width: `${contentWidth}%`
		},
		width: `100%`
	}
	return (
		<div style={style} className='pt-4 md:pt-0 md:p-4'>
			{tabData.map((tab) =>
				tab.subTabs ? tab.subTabs.map((subTab) => <div key={subTab.key}>{activeTab === subTab.key && subTab.content}</div>) : <div key={tab.key}>{activeTab === tab.key && tab.content}</div>
			)}
		</div>
	)
}

const Sidebar = ({ tabData, contentWidth = '75' }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(tabData[0].subTabs ? tabData[0].subTabs[0].key : tabData[0].key)
  const [openTabs, setOpenTabs] = useState(tabData[0].subTabs ? { [tabData[0].key]: true } : {})

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey)
  }

  const toggleSubTab = (tabKey) => {
    setOpenTabs((prev) => ({ ...prev, [tabKey]: !prev[tabKey] }))
  }

  return (
    <>
      <div className='w-full md:w-1/4 pt-2 md:pt-12 md:bg-gray-200 md:min-h-[713px] md:max-h-screen text-base md:text-lg flex md:block gap-1 overflow-x-auto'>
        {tabData.map((tab) =>
          tab.subTabs ? (
            <React.Fragment key={tab.key}>
              <button
                className='w-full text-center md:text-left my-1 px-3 md:px-0 md:pl-6 box-border py-3 uppercase rounded-md md:rounded-none border border-gray-300 md:border-0 '
                onClick={() => toggleSubTab(tab.key)}
              >
                {t(tab.key)}
                <span className='hidden md:inline'>
                  <i className='fa fa-chevron-down' />
                </span>
              </button>
              {openTabs[tab.key] && (
                <>
                  <div className='pl-4 hidden md:block'>
                    {tab.subTabs.map((subTab) => (
                      <TabButton key={subTab.key} label={t(subTab.key)} isActive={activeTab === subTab.key} onClick={() => handleTabChange(subTab.key)} />
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          ) : tab.onClick ? (
            <button key={tab.key} className='w-full text-center md:text-left my-1 px-6 box-border py-3 uppercase rounded-md md:rounded-none border border-gray-300 md:border-0' onClick={tab.onClick}>
              <div className='flex justify-center md:justify-start items-center'>
                {t(tab.key)}
                { tab.logo && <img src={tab.logo} alt={tab.key} className='w-5 h-auto inline-block ml-5' /> }
              </div>
            </button>
          ) : (
            <TabButton logo={tab.logo} key={tab.key} label={t(tab.key)} isActive={activeTab === tab.key} onClick={() => handleTabChange(tab.key)} />
          )
        )}
      </div>
      <div className='block md:hidden'>
        {tabData.map((tab) => (
          <React.Fragment key={tab.key}>
            {tab.subTabs && (
              <select className='w-full py-4 px-2 bg-white border border-gray-300 rounded-md' onChange={(e) => handleTabChange(e.target.value)}>
                {tab.subTabs.map((subTab) => (
                  <option value={subTab.key} key={subTab.key}>
                    {t(subTab.key)}
                  </option>
                ))}
              </select>
            )}
          </React.Fragment>
        ))}
      </div>

      <TabContent activeTab={activeTab} tabData={tabData} contentWidth={contentWidth} />
    </>
  )
}

export default Sidebar
