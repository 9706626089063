import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/swiper-bundle.css'

import useCrud from '../../../hooks/useCRUD'

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

export default function MacroeconomicsChart({ category, translationKey, yTitle }) {
	const { i18n, t } = useTranslation()
	const { resourceList = {}, getAllResources } = useCrud('macroeconomy/graph')
	let data = []

	useEffect(() => {
		if (category) {
			getAllResources({ lang: i18n.language, category })
		}
		// eslint-disable-next-line
	}, [i18n.language, category])

	if (resourceList?.data) {
		data = convertChartData(resourceList.data)
	}

	function convertChartData(inputData) {
		const { data, labels } = inputData
		const monthToPeriod = {
			Januari: 'I',
			Februari: 'I',
			Maret: 'I',
			April: 'II',
			Mei: 'II',
			Juni: 'II',
			Juli: 'III',
			Agustus: 'III',
			September: 'III',
			Oktober: 'IV',
			November: 'IV',
			Desember: 'IV'
		}

		return data.map((value, index) => {
			const label = labels[index]

			const parts = label.split(' ')
			const quarterStr = parts[0]
			const year = parts[1]

			let period
			if (Object.keys(monthToPeriod).includes(quarterStr)) {
				period = monthToPeriod[quarterStr]
			} else {
				switch (quarterStr) {
					case 'Q1':
						period = 'I'
						break
					case 'Q2':
						period = 'II'
						break
					case 'Q3':
						period = 'III'
						break
					case 'Q4':
						period = 'IV'
						break
					default:
						period = quarterStr
				}
			}

			return {
				period,
				year,
				value
			}
		})
	}

	// Custom tick formatter for X axis
	const CustomXAxisTick = (props) => {
		const { x, y, payload } = props
		const { value, index } = payload

		// Every 4 items (complete year), show the year
		const showYear = index % 4 === 0
		// For each quarter, show the Roman numeral
		const quarter = data[index]?.period

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor='middle' fill='#666' fontSize={10}>
					{quarter}
				</text>
				{showYear && (
					<text x={0} y={0} dy={34} textAnchor='middle' fill='#666' fontSize={10}>
						{data[index]?.year}
					</text>
				)}
			</g>
		)
	}

	function getDomains(values) {
		if (!Array.isArray(values) || values.length === 0) {
			return [0,0]
		}

		const min = Math.min(...values)
		const max = Math.max(...values)

		return [min, max]
	}

	return (
		<div className='w-full h-60 p-4 bg-white border border-emerald-700 rounded cursor-pointer'>
			<div className='text-gray-700 font-semibold mb-5'>
				<b>{t(translationKey)}</b>
			</div>
			<ResponsiveContainer width='100%' height='100%' margin="0">
				<LineChart data={data} margin={{ top: 20, right: 20, bottom: 40, left: -25 }}>
					<CartesianGrid strokeDasharray='3 3' vertical={false} />
					<XAxis dataKey='period' axisLine={{ stroke: '#E0E0E0' }} tick={<CustomXAxisTick />} tickSize={8} height={50} padding={{ left: 0 , right: 0}} />
					<YAxis domain={getDomains(data.value)} ticks={data.value} axisLine={{ stroke: '#E0E0E0' }} tick={{ fontSize: 12 }} />
					<Tooltip formatter={(value) => [`${value ? Number(value).toFixed(2) : ``}`, 'Value']} labelFormatter={(label, item) => `${label} ${item[0]?.payload?.year}`} />
					<Line type='monotone' dataKey='value' stroke='#176B87' strokeWidth={2.5} dot={false} activeDot={{ r: 6 }} />
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}
