import React, { useEffect } from 'react'
import HeadingUnderline from '../HeadingUnderline'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import investmentChallengeStore from '../../stores/investmentChallengeStore'
import i18n from '../../i18n'

function Documentation() {
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      await investmentChallengeStore.fetchDocumentation()
    }
    fetchData()
    //eslint-disable-next-line
  }, [i18n.language])

  return (
    <div>
      <div className='mb-2'>
        <h1 className='text-3xl font-bold'>{t('documentation')}</h1>
        <HeadingUnderline />
        <div className='mt-2 flex flex-col gap-3'>
          {investmentChallengeStore.documentation && (
            <>
              {investmentChallengeStore.documentation.file_title1 && investmentChallengeStore.documentation.file_link1 && (
                <a href={investmentChallengeStore.documentation.file_link1} className='text-blue-400 hover:text-blue-600 text-2xl font-bold'>
                  {investmentChallengeStore.documentation.file_title1}
                </a>
              )}
              {investmentChallengeStore.documentation.file_title2 && investmentChallengeStore.documentation.file_link2 && (
                <a href={investmentChallengeStore.documentation.file_link2} className='text-blue-400 hover:text-blue-600 text-2xl font-bold'>
                  {investmentChallengeStore.documentation.file_title2}
                </a>
              )}
              {investmentChallengeStore.documentation.file_title3 && investmentChallengeStore.documentation.file_link3 && (
                <a href={investmentChallengeStore.documentation.file_link3} className='text-blue-400 hover:text-blue-600 text-2xl font-bold'>
                  {investmentChallengeStore.documentation.file_title3}
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default observer(Documentation)
