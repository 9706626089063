import React, { useEffect } from 'react'
import HeadingUnderline from '../HeadingUnderline'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import investmentChallengeStore from '../../stores/investmentChallengeStore'
import i18n from '../../i18n'

function Tor() {
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      await investmentChallengeStore.fetchTor()
    }
    fetchData()
    //eslint-disable-next-line
  }, [i18n.language])

  return (
    <div>
      <div className='mb-2'>
        <h1 className='text-3xl font-bold'>{t('tor')}</h1>
        <HeadingUnderline />
        <div className='mt-2 flex flex-col gap-3'>
          {investmentChallengeStore.tor && (
            <>
              {investmentChallengeStore.tor.file_title1 && investmentChallengeStore.tor.file_link1 && (
                <div className='flex justify-between'>
                  <p className='text-blue-400 text-2xl font-bold'>{investmentChallengeStore.tor.file_title1}</p>
                  <a href={investmentChallengeStore.tor.file_link1} target='_blank' rel='noreferrer' className='bg-gold text-white text-2xl p-2 rounded-md hover:opacity-80'>
                    {t('download')}
                  </a>
                </div>
              )}
              {investmentChallengeStore.tor.file_title2 && investmentChallengeStore.tor.file_link2 && (
                <div className='flex justify-between'>
                  <p className='text-blue-400 text-2xl font-bold'>{investmentChallengeStore.tor.file_title2}</p>
                  <a href={investmentChallengeStore.tor.file_link2} target='_blank' rel='noreferrer' className='bg-gold text-white text-2xl p-2 rounded-md hover:opacity-80'>
                    {t('download')}
                  </a>
                </div>
              )}
              {investmentChallengeStore.tor.file_title3 && investmentChallengeStore.tor.file_link3 && (
                <div className='flex justify-between'>
                  <p className='text-blue-400 text-2xl font-bold'>{investmentChallengeStore.tor.file_title3}</p>
                  <a href={investmentChallengeStore.tor.file_link3} target='_blank' rel='noreferrer' className='bg-gold text-white text-2xl p-2 rounded-md hover:opacity-80'>
                    {t('download')}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default observer(Tor)
