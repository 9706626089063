import React from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../components/Breadcrumb'

function InternationalTrade() {
	const { t } = useTranslation()

	const breadcrumbItems = [{ label: t('home'), href: '/' }, { label: t('invest') }]

	return (
		<div className='flex flex-col justify-center mb-12'>
			<div className='max-w-6xl w-full mt-5 md:mt-24 p-2 md:p-0 md:mx-auto'>
				<Breadcrumb items={breadcrumbItems} />
				<div className='flex flex-col gap-5 py-5'>
					<>
						<img src={`assets/images/investment/international-trade-1.jpeg`} alt='banner' />
						<img src={`assets/images/investment/international-trade-2.jpeg`} alt='banner' />
					</>
				</div>
			</div>
		</div>
	)
}

export default InternationalTrade;
