import React from 'react'
import { useTranslation } from 'react-i18next'
import HeadingUnderline from '../../../components/HeadingUnderline'
import Sidebar from '../../../components/Sidebar'
import InvestmentPotentialSection from '../../../components/Investment/InvestmentPotential/InvestmentPotentialSection'
import { observer } from 'mobx-react'

function InvestmentPotential() {
	const { t } = useTranslation()

	const tabs = [
		{
			key: 'portfolio',
			subTabs: [
				{
					key: 'all-categories',
					content: <InvestmentPotentialSection category='all-categories' />
				},
				{
					key: 'agriculture-forestry-fishery',
					content: <InvestmentPotentialSection category='agriculture-forestry-fishery' />
				},
				{
					key: 'manufacturing-industry',
					content: <InvestmentPotentialSection category='manufacturing-industry' />
				},
				{
					key: 'electricity-gas-procurement',
					content: <InvestmentPotentialSection category='electricity-gas-procurement' />
				},
				{
					key: 'waste-management',
					content: <InvestmentPotentialSection category='waste-management' />
				},
				{
					key: 'trade',
					content: <InvestmentPotentialSection category='trade' />
				},
				{
					key: 'other-services',
					content: <InvestmentPotentialSection category='other-services' />
				}
			]
		},
		{
			key: 'ptsp-project',
			onClick: () => {
				window.open('https://cjip.jatengprov.go.id/peluang-investasi', '_blank')
			},
			logo: 'assets/images/cjip.png'
		}
	]

	return (
		<>
			<h1 className='text-3xl font-bold mb-2 mt-8'>{t('potential-invest')}</h1>
			<HeadingUnderline />
			<div className='md:flex mt-7'>
				<Sidebar tabData={tabs} />
			</div>
		</>
	)
}

export default observer(InvestmentPotential)
