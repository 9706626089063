import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import Investment from './pages/Investment'
import ContactUs from './pages/ContactUs'
import Layout from './components/Layout'
import AboutUs from './pages/AboutUs'
import ChartDetail from './pages/Home/ChartDetail'
import Faq from './pages/Faq'
import i18n from './i18n'
import IndustrialAreaDetail from './pages/Investment/FacilityInfrastructure/IndustrialAreaDetail'
import InfrastuctureDetail from './pages/Investment/FacilityInfrastructure/InfrastuctureDetail'
import SuccessStoryDetail from './pages/Investment/SuccessStory/SuccessStoryDetail'
import InvestmentPotentialDetail from './pages/Investment/InvestmentPotential/InvestmentPotentialDetail'
import News from './pages/News'
import NewsDetail from './pages/News/NewsDetail'
import InvestmentRealization from './pages/Home/InvestmentRealization'
import InternationalTrade from './pages/Home/InternationalTrade'
import LinkPage from './pages/Home/LinkPage'
import RegionDetail from './pages/Home/RegionDetail'
import Trade from './pages/Trade'
import InvestmentChallenge from './pages/Challenge'
import ResetPassword from './pages/ResetPassword'
import { ToastContainer } from 'react-toastify'
import Tourism from './pages/Tourism'
import TourismDetail from './pages/Tourism/TourismDetail'

function App() {
	const location = useLocation()

	useEffect(() => {
		document.title = 'Keris Jateng'
	}, [])

	useEffect(() => {
		console.log('Route changed to:', location.pathname)
		window.scrollTo(0, 0)

		const supportedLanguages = ['id', 'en', 'ja', 'ko', 'zh']
		const currentLanguage = localStorage.getItem('i18nextLng')

		if (!supportedLanguages.includes(currentLanguage)) {
			i18n.changeLanguage('id')
			localStorage.setItem('i18nextLng', 'id')
		}
	}, [location.pathname])

	return (
    <div className='App'>
      <ToastContainer />
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/invest-realization' element={<InvestmentRealization />} />
          <Route path='/international-trade' element={<InternationalTrade />} />
          <Route path='/statistic/:postName' element={<ChartDetail />} />
          <Route path='/investment'>
            <Route index element={<Investment />} />
            <Route path='/investment/industrial-area/:postName' element={<IndustrialAreaDetail />} />
            <Route path='/investment/infrastructure/:postName' element={<InfrastuctureDetail />} />
            <Route path='/investment/success-story/:postName' element={<SuccessStoryDetail />} />
            <Route path='/investment/investment-potential/:postName' element={<InvestmentPotentialDetail />} />
          </Route>
          <Route path='/news'>
            <Route index element={<News />} />
            <Route path='/news/:postName' element={<NewsDetail />} />
          </Route>
          <Route path='/pariwisata'>
            <Route index element={<Tourism />} />
            <Route path='/pariwisata/:postName' element={<TourismDetail />} />
          </Route>
          <Route path='/link/:postName' element={<LinkPage />} />
          <Route path='/region/:postName' element={<RegionDetail />} />
          <Route path='/trade' element={<Trade />} />
          <Route path='/challenge' element={<InvestmentChallenge />} />
          <Route path='/contact_us' element={<ContactUs />} />
          <Route path='/about_us' element={<AboutUs />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/reset-password' element={<ResetPassword />} />
        </Routes>
      </Layout>
    </div>
  )
}

function AppWrapper() {
	return (
		<Router>
			<App />
		</Router>
	)
}

export default AppWrapper
