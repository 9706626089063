import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { industrialAreaStore } from '../../../stores/_rootStore'
import industrialAreaDetailStore from '../../../stores/industrialAreaDetailStore'

function FacilityInfrastructureSection({ category }) {
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await industrialAreaDetailStore.fetchData('kawasan-industri-di-jawa-tengah')
        if (category === 'industrial-area-invest' && industrialAreaStore.data.length === 0) {
          await industrialAreaStore.fetchData()
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
    return () => {
      if (category === 'industrial-area-invest') industrialAreaStore.resetState()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-5'>{t(category)}</h3>
      {category === 'sdm-long' && (
        <div className='flex flex-col gap-2 mb-5'>
          <img
            src='/assets/images/investment/sdm-1.png'
            alt='SDM Jateng 1'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/sdm-2.png'
            alt='SDM Jateng 2'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/sdm-3.png'
            alt='SDM Jateng 3'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
        </div>
      )}
      {category === 'infrastructure' && (
        <div className='flex flex-col gap-2 mb-5'>
          <img
            src='/assets/images/investment/infra-1.png'
            alt='Infrastructur Jateng 1'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/infra-2.png'
            alt='Infrastructur Jateng 2'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/infra-3.png'
            alt='Infrastructur Jateng 3'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
          <img
            src='/assets/images/investment/infra-4.png'
            alt='Infrastructur Jateng 4'
            className='cursor-pointer'
            onClick={(e) => {
              e.currentTarget.requestFullscreen()
            }}
          />
        </div>
      )}
      <div className='py-5'>
        {category === 'industrial-area-invest' &&
          (industrialAreaDetailStore.isLoading ? (
            <p>Loading...</p>
          ) : industrialAreaDetailStore?.data?.post_content ? (
            <p dangerouslySetInnerHTML={{ __html: industrialAreaDetailStore.data.post_content }}></p>
          ) : (
            <p>{t('no-data')}</p>
          ))}
      </div>
      <div className='flex justify-center'>
        {category === 'industrial-area-invest' && industrialAreaStore.hasNext && (
          <button className='bg-gold text-white py-2 px-4 rounded-md' onClick={industrialAreaStore.nextPage}>
            {t('view-more')}
          </button>
        )}
      </div>
    </div>
  )
}

export default observer(FacilityInfrastructureSection)
